import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import aboutImg from '../Assets/Images/aboutCmg.avif';
import { NavBar, FooterCgn, CopyRightFooter } from '../Components/Header_Footer';
import afterMarket from '../Assets/Images/cgn-after-market.jpg';
import oriImg from '../Assets/Images/cgn-equipments.jpg';
import walletImg from '../Assets/Images/walletImg.svg'
import cartImg from '../Assets/Images/cartImg.svg'
import vanImg from '../Assets/Images/vanImg.svg'
import { TiTick } from "react-icons/ti";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../All css/AboutUs.css'
import '../All css/Home.css';


const AboutUs = () => {
    return (
        <div>

            <NavBar />

            <div className='about-banner'>

                <h1 className='banner-h'>About Us</h1>

            </div> <br /> <br />

            <Container>
                <div className='about-cmg'>

                    <h1 className='originol-txt'>
                        WHY GM GENUINE PARTS AND ACDELCO?
                    </h1>
                    <p className='originol-txt'>
                        General Motors drivers value confidence in their vehicles, the premium parts that help
                        keep them on the road, and the professionals that maintain and repair them. GM Genuine Parts
                        and ACDelco are proud to work together as the only source for true General Motors Original
                        Equipment and aftermarket parts. That’s a responsibility we don’t take lightly, as we constantly
                        strive to earn drivers’ vote of confidence by:
                        <br /><br />
                        <li>Building and innovating on the quality of GM Parts</li>
                        <li>Building and innovating on the quality of GM Parts</li>
                        <li>Building and innovating on the quality of GM Parts</li>
                        <br />
                        Innovation brings challenge and opportunity, and we’re suiting up for both. When it comes to
                        the evolving maintenance, repair, collision and powertrain needs of Chevrolet, Buick, GMC and
                        Cadillac vehicles, only GM Genuine Parts and ACDelco offer true GM Original Equipment built for what's next.
                        <br /><br />
                        That’s why with GM Genuine Parts and ACDelco – Certainty Starts Here.

                    </p>

                </div>
            </Container> <br />

            <div className='about-cmg-bg'><br /><br />
                <Container>

                    <Row>

                        <Col lg={6} md={12} sm={12}>
                            <img src={aboutImg} alt="about CMG" className='img-fluid' />
                        </Col>
                        <Col lg={6} md={12} sm={12}>

                            <div className='img-txt'>
                                <h2 className='originol-txt'>
                                    OUR HISTORY – A RUNNING START
                                </h2>
                                <p className='originol-txt'>
                                    For more than 100 years,† General Motors has pushed the limits of transportation and technology,
                                    and we’re proud to have helped drive that innovation. GM Genuine Parts and ACDelco are proud to work
                                    together as the only source for true General Motors Original Equipment and aftermarket parts.
                                    Our roots empower us. ACDelco has been at the forefront of automotive innovation since the birth of GM
                                    in 1908, but a strong start in automotive wasn’t enough. We’ve helped a woman fly around the world and
                                    a man traverse the surface of the moon. However, our history isn’t half as promising as our future as
                                    we continue to go forward with GM Genuine Parts by our side to offer and grow our technical training
                                    and rewards programs. From the assembly line to the finish line, GM technological innovation and
                                    improvement continues to build with GM Original Equipment as our foundation.
                                </p>

                            </div>

                        </Col>

                    </Row>

                    <br /><br /><br />

                    <Row>

                        <Col lg={6} md={12} sm={12}>

                            <div className='cmg-cards'>
                                <img src={oriImg} alt="" className='img-fluid' />
                                <h3 className='cmg-originol'>CGN ORIGINOL EQUIPMENT</h3>
                                <p className='originol-txt'>
                                    GM Genuine Parts and ACDelco Original Equipment parts are built on innovation that never lets up.
                                    When you choose a true OE part designed, engineered, tested and backed by GM for your vehicle, you’re
                                    making the right choice.
                                </p>
                            </div>

                        </Col>

                        <Col lg={6} md={12} sm={12}>

                            <div className='cmg-cards'>
                                <img src={afterMarket} alt="" className='img-fluid' />
                                <h3 className='after-market'>AFTER MARKET</h3>
                                <p className='originol-txt'>
                                    GM Genuine Parts and ACDelco Original Equipment parts are built on innovation that never lets up.
                                    When you choose a true OE part designed, engineered, tested and backed by GM for your vehicle, you’re
                                    making the right choice.
                                </p>
                            </div>

                        </Col>

                    </Row>


                </Container> <br /><br /><br />

            </div>

            {/* -------------- Last Section Start ----------  */}

            <Container>
                <div>

                    <br /><br /><br />

                    <Row >

                        <Col lg={4} md={6} sm={12} xs={12} className='flex-row pb-4'>

                            <div className="item-self">
                                <img src={vanImg} height={50} alt="" />
                            </div>
                            <div className="px-4 item-self">
                                <p className='item-self'>
                                    <h5> Secure Payments </h5>
                                    <span className='last-sec-p'> MWe deliver what we show </span> <br />
                                    <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span> <br />
                                    <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span>
                                </p>
                            </div>

                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} className='flex-row pb-4'>

                            <div className="item-self">
                                <img src={cartImg} height={50} alt="" />
                            </div>
                            <div className="px-4 item-self">
                                <p className='item-self'>
                                    <h5> Secure Payments </h5>
                                    <span className='last-sec-p'> MWe deliver what we show </span> <br />
                                    <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span> <br />
                                    <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span>
                                </p>
                            </div>

                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} className='flex-row pb-4'>

                            <div className="item-self">
                                <img src={walletImg} height={50} alt="" />
                            </div>
                            <div className="px-4 item-self">
                                <p className='item-self'>
                                    <h5> Secure Payments </h5>
                                    <span className='last-sec-p'> MWe deliver what we show </span> <br />
                                    <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span> <br />
                                    <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span>
                                </p>
                            </div>

                        </Col>

                    </Row>

                </div> <br />
            </Container>

            {/* -------------- Last Section End ----------  */}

            {/* --------------------- Footer Start -------------------- */}

            <div className='footer-bg'><br /><br />
                <Container>
                    <FooterCgn />
                </Container>
            </div>

            <CopyRightFooter />

            {/* *************** Footer End ************* */}



        </div>
    )
}

export default AboutUs