import React from 'react';
import { NavBar, FooterCgn, CopyRightFooter } from '../Components/Header_Footer';
import { Container, Row, Col } from 'react-bootstrap';
import walletImg from '../Assets/Images/walletImg.svg';
import cartImg from '../Assets/Images/cartImg.svg';
import vanImg from '../Assets/Images/vanImg.svg';
import { TiTick } from "react-icons/ti";
import { CiLocationOn } from "react-icons/ci";
import { FiPhoneCall } from "react-icons/fi";
import { IoMailOpenOutline } from "react-icons/io5";


import '../All css/ContactUs.css';
import '../All css/Home.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const ContactUs = () => {
    return (
        <div>

            {/* --------------------- Header Start -------------------- */}

            <NavBar />

            {/* *************** Header End ************* */}

            <div className='about-banner'>

                <h1 className='banner-h'>Contact Us</h1>

            </div>


            {/* --------------------- Contact section Start -------------------- */}

            <div className="contact-bg"><br /> <br />

                <div className='contact-sec'>

                    <h4 className='in-block t-align'>
                        CONTACT US
                    </h4><br />

                    <h1 className='t-align theme-clr c-h-bold hhh'>
                        Get closer to Us
                    </h1>

                    <p className='t-align text-lighter'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione, fuga.
                    </p>
                    <p className='t-align t-l-height text-lighter'>
                        Lorem ipsum dolor sit amet consectetur adipisicing.
                    </p><br /><br />

                    <div className='c-cards'>

                        <Container>

                            <Row>
                                <Col lg={4} md={6} sm={12} className='mb-1'>
                                    <div className='inner-card inner-bg-black '>

                                        <CiLocationOn className='theme-clr i-size' />
                                        <h3 className='t-align w-clr '>Address</h3><br />
                                        <p className='t-align w-clr t-l-height'>
                                            Lorem ipsum dolor sit amet.
                                        </p>

                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} className='mb-1'>
                                    <div className='inner-card inner-bg'>

                                        <FiPhoneCall className='i-size' />
                                        <h3 className='t-align '>Phone Number</h3><br />
                                        <p className='t-align t-l-height'>
                                            +971 4 224 2274
                                        </p>

                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} className='mb-1'>
                                    <div className='inner-card inner-bg-black'>

                                        <IoMailOpenOutline className='theme-clr i-size' />
                                        <h3 className='t-align w-clr'>Our mailbox</h3><br />
                                        <p className='t-align w-clr t-l-height'>
                                            sales@cgnparts.com
                                        </p>

                                    </div>
                                </Col>
                            </Row>

                        </Container>

                    </div>

                </div><br /> <br /><br /> <br />

            </div>

            {/* *************** Contact section End ************* */}

            {/* -------------- Last Section Start ----------  */}

            <Container>
                <div><br /><br /><br />

                    <Row >

                        <Col lg={4} md={6} sm={12} xs={12} className='flex-row pb-4'>

                            <div className="item-self">
                                <img src={vanImg} height={50} alt="" />
                            </div>
                            <div className="px-4 item-self">
                                <p className='item-self'>
                                    <h5> Secure Payments </h5>
                                    <span className='last-sec-p'> MWe deliver what we show </span> <br />
                                    <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span> <br />
                                    <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span>
                                </p>
                            </div>

                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} className='flex-row pb-4'>

                            <div className="item-self">
                                <img src={cartImg} height={50} alt="" />
                            </div>
                            <div className="px-4 item-self">
                                <p className='item-self'>
                                    <h5> Secure Payments </h5>
                                    <span className='last-sec-p'> MWe deliver what we show </span> <br />
                                    <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span> <br />
                                    <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span>
                                </p>
                            </div>

                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} className='flex-row pb-4'>

                            <div className="item-self">
                                <img src={walletImg} height={50} alt="" />
                            </div>
                            <div className="px-4 item-self">
                                <p className='item-self'>
                                    <h5> Secure Payments </h5>
                                    <span className='last-sec-p'> MWe deliver what we show </span> <br />
                                    <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span> <br />
                                    <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span>
                                </p>
                            </div>

                        </Col>

                    </Row>

                </div><br />
            </Container>

            {/* -------------- Last Section End ----------  */}

            {/* --------------------- Footer Start -------------------- */}

            <div className='footer-bg'><br /><br />
                <Container>
                    <FooterCgn />
                </Container>
            </div>

            <CopyRightFooter />

            {/* *************** Footer End ************* */}


        </div>
    )
}

export default ContactUs