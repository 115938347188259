import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../Assets/Images/CGN Logo.jpg';
import { FaBarsStaggered } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { AiOutlineYoutube } from "react-icons/ai";
import { IoLogoFacebook } from "react-icons/io5";
import { RxInstagramLogo } from "react-icons/rx";
import { Container, Row, Col } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../All css/Header_Footer.css';

export const NavBar = () => {

    let [toogleBar, SetToogleBar] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        <header className='header'>
            <Container>
                <nav>


                    <Link to={'/'}>
                        <img src={logo} alt="Logo" className='logo' />
                    </Link>

                    <ul id='navbar' className={toogleBar ? '#navbar active' : '#navbar'}>
                        <li>
                            <Link to={'/'} className='linkto active'>Home</Link>
                        </li>
                        <li className=''>
                            <Link to={'/aboutus'} className='linkto ' >About us</Link>

                        </li>
                        <li>
                            <Link to={'/'} className='linkto' >Products</Link>

                        </li>
                        <li>
                            <Link to={'/contactus'} className='linkto' >Contact us</Link>

                        </li>
                    </ul>

                    <div id='mobile'>

                        <Offcanvas show={show} onHide={handleClose} className="toggle" style={{width:320}}>
                            <Offcanvas.Header className='toggle-header'>

                                <Link to={'/'}>
                                    <img src={logo} alt="Logo" className='logo' />
                                </Link>

                                <div className='close-toggle' onClick={handleClose}>

                                    <IoClose className='close-icon' />

                                </div>


                            </Offcanvas.Header>
                            <Offcanvas.Body>

                                <ul className='toggle-list-style space-y-10'>
                                    <li>
                                        <Link to={'/'} className='linkto active'>Home</Link>
                                    </li>
                                    <li className=''>
                                        <Link to={'/aboutus'} className='linkto ' >About us</Link>

                                    </li>
                                    <li>
                                        <Link to={'/'} className='linkto' >Products</Link>

                                    </li>
                                    <li>
                                        <Link to={'/contactus'} className='linkto' >Contact us</Link>

                                    </li>
                                </ul>


                            </Offcanvas.Body>
                        </Offcanvas>

                        {
                            !toogleBar ?
                                <span onClick={handleShow} >
                                    <FaBarsStaggered id='bar' />
                                </span>
                                :
                                <span onClick={handleClose}>
                                    <IoClose id='bar' />
                                </span>

                        }


                    </div>


                </nav>

            </Container>
        </header>

    )
}

export const FooterCgn = _ => {

    let [emailSubscribe, setEmailSubscribe] = useState("");

    const handleClick = _ => {

        console.log(emailSubscribe)

        if (emailSubscribe.endsWith("@gmail.com")) {

            toast.success("Request has been sent for Subscription", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            let inp = document.getElementById("email");
            inp.innerText = "";

        }
        else {
            toast.error("Something wrong", {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

        }
    }

    return (

        <footer>

            <div className='footer-row'>

                <Row>

                    <Col lg={4} md={6} sm={12}>

                        <div className="foot-sec p-4" >

                            <Link to={'/'}>
                                <img src={logo} alt="Logo" className='logo' />
                            </Link>
                            <p className='text-white footer-txt'>
                                CGN.autoparts – No.1 online Autostore for Car care and Car Accessories in Pakistan
                            </p>
                            <div className='foot-scoial-icon'>

                                <div className='y-icon'>
                                    <AiOutlineYoutube />
                                    {/* <AiFillYoutube /> */}
                                </div>
                                <div className='f-icon'>
                                    <IoLogoFacebook />
                                </div>
                                <div className='i-icon'>
                                    <RxInstagramLogo />
                                </div>


                            </div>

                        </div>

                    </Col>
                    <Col lg={2} md={6} sm={12}>

                        <div className="foot-sec text-white p-4" >
                            <p className='footer-h'>Qick Links</p>
                            <Link to={'/'} className='footer-link mt-2 mb-2 footer-txt'>Home</Link>
                            <Link to={'/aboutus'} className='footer-link mb-2 footer-txt'>About us</Link>
                            <Link to={'/'} className='footer-link mb-2 footer-txt'>Products</Link>
                            <Link to={'/contactus'} className='footer-link mb-2 footer-txt'>Contact us</Link>
                        </div>

                    </Col>
                    <Col lg={3} md={6} sm={12}>

                        <div className="foot-sec text-white p-4" >
                            <p className='footer-h'>Get in Touch</p>
                            <p className='footer-txt'>Call or WhatsApp</p>
                            <h4 className='footer-num line-height' >03313995559</h4>
                            <br />
                            <p className='footer-txt'>Email Us</p>
                            <h5 className='footer-email line-height' >tennesseetrademarket@gmail.com</h5>

                        </div>

                    </Col>
                    <Col lg={3} md={6} sm={12}>

                        <div className="foot-sec text-white p-4" >
                            <p className='footer-h'>Stay Updated</p>
                            <p className='footer-txt'>Get exciting discounts on your email address</p>
                            <form action="">
                                <input
                                    type="email"
                                    className='foot-inp'
                                    required
                                    placeholder='Your email address'
                                    id="email"
                                    onChange={(e) => setEmailSubscribe(e.target.value)}
                                /> <br /> <br />
                                <button type='submit' className='btn-red' onClick={handleClick}>Subscribe</button>
                            </form>
                        </div>

                    </Col>

                </Row>





            </div>

            <ToastContainer />

        </footer>

    )

}

export const CopyRightFooter = _ => {
    return (
        <footer>
            <div className='footer-cright '> <br /> <br /><br />
                <p>
                    Copyright © 2024 - CGN.autoparts All Rights Reserved.
                </p>
            </div>

        </footer>
    )
}

