import React from 'react';
import { Route, Routes } from 'react-router';
import Home from '../Container/Home';
import AboutUs from '../Container/AboutUs';
import ContactUs from '../Container/ContactUs';
import Products from '../Container/Products';
import PageNotFound from '../Components/404';

const Navigation = () => {
  return (
    <div>
        <Routes>
            <Route path='/' element={<Home/>}></Route>
            <Route path='/aboutus' element={<AboutUs/>}></Route>
            <Route path='/contactus' element={<ContactUs/>}></Route>
            <Route path='/cmg/products' element={<Products/>}></Route>
            <Route path='/*' element={<PageNotFound/>}></Route>
        </Routes>
    </div>
  )
}

export default Navigation