import React from 'react';
import { NavBar, FooterCgn, CopyRightFooter } from '../Components/Header_Footer';
import Carousel from 'react-bootstrap/Carousel';
import imgSlider from '../Assets/Images/banner1.jpg';
import imgSlider2 from '../Assets/Images/banner2.jpg';
import imgSlider3 from '../Assets/Images/banner5.jpg';
import batteryImg from '../Assets/Images/battery.svg';
import filterImg from '../Assets/Images/filter.svg';
import breakImg from '../Assets/Images/break.svg';
import rodeImg from '../Assets/Images/rode.svg';
import transmission from '../Assets/Images/transmission.svg';
import engine from '../Assets/Images/enguine.svg';
import ignition from '../Assets/Images/ignition.svg';
import bodyImg from '../Assets/Images/body.svg';
import walletImg from '../Assets/Images/walletImg.svg'
import cartImg from '../Assets/Images/cartImg.svg'
import vanImg from '../Assets/Images/vanImg.svg'
import spareLook from '../Assets/Images/homeImg1.jpg'
import carImg from '../Assets/Images/homeImg4.jpg'
import spareTable from '../Assets/Images/homeImg5.jpg';
import { Container, Row, Col } from 'react-bootstrap';
import { TiTick } from "react-icons/ti";
import 'bootstrap/dist/css/bootstrap.min.css';

import '../All css/Home.css';


const Home = () => {
  return (
    <div>

      {/* --------------------- Header Start -------------------- */}

      <NavBar />

      {/* *************** Header End ************* */}

      {/* --------------------- slider start -------------------- */}

      <Carousel>
        <Carousel.Item interval={1000}>
          <img src={imgSlider} alt="" width={'100%'} height={'550px'} />
          <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img src={imgSlider2} alt="" width={'100%'} height={'550px'} />
          <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={9000}>
          <img src={imgSlider3} alt="" width={'100%'} height={'550px'} />
          <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      {/* *************** slider end ************* */}
      <br /><br /><br />

      <Container>
        {/* -------------- Three Image Section Start ----------  */}

        <Row className='flex-btw' >

          <Col lg={4} md={6} sm={12} xs={12} className='flex pb-2' >
            <img src={spareLook} alt="" className='img-fluid img-frame' />
          </Col>

          <Col lg={4} md={6} sm={12} xs={12} className='flex pb-2' >
            <img src={spareTable} alt="" className='img-fluid img-frame' />

          </Col>

          <Col lg={4} md={6} sm={12} xs={12} className='flex pb-2'  >
            <img src={carImg} alt="" className='img-fluid img-frame' />

          </Col>

        </Row><br /><br /><br />

        {/* ************** Three Image Section End **************  */}

        {/* -------------- Cards Section Start ----------  */}

        <div className=''>

          <h2 className='text-center h-style'>Popular Parts for your vehicle</h2>
          <h6 className='text-center h-light'>Your ride will be proud salute more salute</h6> <br /> <br />

          <div className=''>

            <Row className='flex-row'>

              <Col className='cat-card' xl={3} lg={6} md={6} sm={12} >

                <div className='card-brdr'>
                  <img src={batteryImg} alt="" className='img-fluid card-img' />
                  <h2>Battery</h2>
                </div>

              </Col>
              <Col className='cat-card' xl={3} lg={6} md={6} sm={12} >

                <div className='card-brdr'>
                  <img src={breakImg} alt="" className='img-fluid card-img' />
                  <h2>Break system</h2>
                </div>

              </Col>
              <Col className='cat-card' xl={3} lg={6} md={6} sm={12} >

                <div className='card-brdr'>
                  <img src={filterImg} alt="" className='img-fluid card-img' />
                  <h2>Filter</h2>
                </div>

              </Col>
              <Col className='cat-card' xl={3} lg={6} md={6} sm={12} >

                <div className='card-brdr'>
                  <img src={engine} alt="" className='img-fluid card-img' />
                  <h2>Engine</h2>
                </div>

              </Col>

            </Row>
            <Row className='flex-row'>

              <Col className='cat-card' xl={3} lg={6} md={6} sm={12}>

                <div className='card-brdr'>
                  <img src={transmission} alt="" className='img-fluid card-img' />
                  <h2>Transmission</h2>
                </div>

              </Col>
              <Col className='cat-card' xl={3} lg={6} md={6} sm={12} >

                <div className='card-brdr'>
                  <img src={ignition} alt="" className='img-fluid card-img' />
                  <h2>Ignition</h2>
                </div>

              </Col>
              <Col className='cat-card' xl={3} lg={6} md={6} sm={12} >

                <div className='card-brdr'>
                  <img src={bodyImg} alt="" className='img-fluid card-img' />
                  <h2>Body</h2>
                </div>

              </Col>
              <Col className='cat-card' xl={3} lg={6} md={6} sm={12} >

                <div className='card-brdr'>
                  <img src={rodeImg} alt="" className='img-fluid card-img' />
                  <h2>Suspension Rode</h2>
                </div>

              </Col>

            </Row>


          </div>

        </div>

        {/* **************** Cards Section End ****************  */}

        {/* -------------- Last Section Start ----------  */}

        <div>

          <br /><br /><br />

          <Row >

            <Col lg={4} md={6} sm={12} xs={12} className='flex-row pb-4'>

              <div className="item-self">
                <img src={vanImg} height={50} alt="" />
              </div>
              <div className="px-4 item-self">
                <p className='item-self'>
                  <h5> Secure Payments </h5>
                  <span className='last-sec-p'> MWe deliver what we show </span> <br />
                  <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span> <br />
                  <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span>
                </p>
              </div>

            </Col>
            <Col lg={4} md={6} sm={12} xs={12} className='flex-row pb-4'>

              <div className="item-self">
                <img src={cartImg} height={50} alt="" />
              </div>
              <div className="px-4 item-self">
                <p className='item-self'>
                  <h5> Secure Payments </h5>
                  <span className='last-sec-p'> MWe deliver what we show </span> <br />
                  <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span> <br />
                  <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span>
                </p>
              </div>

            </Col>
            <Col lg={4} md={6} sm={12} xs={12} className='flex-row pb-4'>

              <div className="item-self">
                <img src={walletImg} height={50} alt="" />
              </div>
              <div className="px-4 item-self">
                <p className='item-self'>
                  <h5> Secure Payments </h5>
                  <span className='last-sec-p'> MWe deliver what we show </span> <br />
                  <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span> <br />
                  <span className='last-sec-span'> <TiTick className='last-sec-tick' /> Big range of genuine products </span>
                </p>
              </div>

            </Col>

          </Row>

        </div><br />

        {/* -------------- Last Section End ----------  */}

      </Container>

      {/* --------------------- Footer Start -------------------- */}

      <div className='footer-bg'><br /><br />
        <Container>
          <FooterCgn />
        </Container>
      </div>

      <CopyRightFooter />

      {/* *************** Footer End ************* */}


    </div>
  )
}

export default Home