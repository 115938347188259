import React from 'react';

const PageNotFound = () => {
  return (
    <div>
        <h1 style={{textAlign:'center'}}> 404. Page not found</h1>
    </div>
  )
}

export default PageNotFound;